import { Text } from "@chakra-ui/react";
import { NewAotzivLogo } from "../../svg";
import { Container } from "./Footer.styles";

export const Footer = () => (
  <Container>
    <NewAotzivLogo />
    <Text fontSize="10px" color="text.heading">
      © ООО «Эвотека», ООО «Окью Компани» 2024
    </Text>
    <Text fontSize="8px" color="text.body">
      Все права защищены ИНН/ОГРН/КПП: 1684015646/1171690072586/165801001
    </Text>
  </Container>
);
