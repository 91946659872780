import { chakra, Link } from "@chakra-ui/react";

export const Container = chakra(Link, {
  baseStyle: {
    borderRadius: "50px",
    backgroundColor: "#E2FFE7",
    padding: "10px 14px",
    display : "inline-flex",
    alignItems : "center",
    gap : "9px"
  },
});
