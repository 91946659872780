import { Button, Link, Spinner, Text, VStack } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Stepper } from "../../common";
import { ControlledImagePicker } from "../../common/ImagePicker/ControlledImagePicker";
import { Header } from "../FormBuilder/FormBuilder.styles";
import { SecondFormSchema } from "../ReviewForm/FormSteps";

export const Second = ({onNext, onBack} : {onNext : any, onBack : any}) => {
  const form = useForm({
    resolver: zodResolver(SecondFormSchema),
    mode: "onSubmit",
  });

  const {
    getValues,
    watch,
    formState: { isSubmitting },
  } = form;

  const [isValid, setValid] = useState(false);

  const variant = isValid ? "primary" : "secondary";

  // Принудительная валидация формы.
  useEffect(() => {
    try {
      SecondFormSchema.parse(getValues());
      setValid(true);
    } catch (e) {
      setValid(false);
    }
  }, [watch()]);

  return (
    <FormProvider {...form}>
      <Stepper activeStepIndex={0} steps={3} onBack={onBack} />
      <VStack gap="12px" w="full" alignItems="start">
        <Header noOfLines={3}>Добавьте скриншот отзыва</Header>
        <Text fontSize="14px" color="text.heading">
          Убедитесь, что на скриншоте четко отображаются данные: никнейм, название филиала, оценка,
          комментарий, дата и статус отзыва.{" "}
          <Link href="" color="accent.red">
            Посмотреть пример отзыва
          </Link>
        </Text>
      </VStack>
      <ControlledImagePicker name="screenshot" />
      <VStack gap="10px" w="full">
        <Button
          onClick={form.handleSubmit(onNext)}
          opacity={isSubmitting || !isValid ? 0.5 : 1}
          variant={variant}
          gap="10px"
        >
          Далее
          {isSubmitting ? <Spinner size={"sm"} color="white" /> : null}
        </Button>
        <Button onClick={onBack} variant="secondary">
            Назад
          </Button>
      </VStack>
    </FormProvider>
  );
};
