import { useState } from "react";
import { Header } from "../components/common";
import { ReviewForm } from "../components/forms";
import { First } from "../components/forms/First/First";
import { Third } from "../components/forms/ReviewForm/Third/Third";
import { Second } from "../components/forms/Second/Second";
import { KamkomBankLogo } from "../components/svg";

const FormSwitcher = ({
  activeIndex,
  onNext,
  onBack,
  defaultValues,
}: {
  activeIndex: number;
  onNext: (data: any) => void;
  onBack: () => void;
  defaultValues: any;
}) => {
  switch (activeIndex) {
    case 0: {
      return <First onNext={onNext} defaultValues={defaultValues} />;
    }
    case 1: {
      return <Second onNext={onNext} onBack={onBack} />;
    }
    default: {
      return <Third onNext={onNext} onBack={onBack} />;
    }
  }
};

export const FormScreen = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [formData, setFormData] = useState<any>({});

  const onNext = (data: any) => {
    setFormData({ ...formData, [activeIndex]: data });
    setActiveIndex(activeIndex + 1);
  };

  const onBack = () => setActiveIndex(activeIndex - 1);

  return (
    <>
      <Header logo={<KamkomBankLogo />} phone="+7(800) 200-86-38" />
      <ReviewForm />
    </>
  );
};
