import { FormBuilderProps } from "./FormBuilder.types";
import { FormSwitcher } from "./FormSwitcher/FormSwitcher";

export const FormBuilder = ({
  defaultValues,
  activeStepIndex,
  description,
  onSubmit,
  onChange,
  formData,
  onBack,
  header,
}: FormBuilderProps) => (
  <FormSwitcher
    header={header}
    description={description}
    activeIndex={activeStepIndex}
    defaultValues={defaultValues}
    formData={formData}
    onChange={onChange}
    onNext={onSubmit}
    onBack={onBack}
  />
);
