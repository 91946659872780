import { Flex, Image, Text } from "@chakra-ui/react";
import { FormatOptionLabelProps, LabelWithIcon } from "./FormatOptionLabel.types";

export const FormatOptionLabel = ({ label }: FormatOptionLabelProps) => (
  <Flex alignItems="center" gap="5px" w="full">
    {(label as LabelWithIcon)?.icon ? (
      <Image w="26px" h="26px" src={(label as LabelWithIcon).icon} />
    ) : null}
    <Text fontSize="16px">{(label as LabelWithIcon)?.name ?? label}</Text>
  </Flex>
);
