import { InputWrapper } from "../../wrappers";
import { CommonSelectProps } from "./CommonSelect.types";

import Select from "react-select";
import { override, styles } from "./CommonSelect.styles";
import { FormatOptionLabel } from "./FormatOptionLabel/FormatOptionLabel";

import "simplebar-react/dist/simplebar.min.css";

export const CommonSelect = ({
  placeholder,
  onChange,
  options,
  value,
  ...props
}: CommonSelectProps) => (
  <InputWrapper {...props}>
    <Select
      components={override(Boolean(props.errorMesssage), onChange)}
      styles={styles(Boolean(props.errorMesssage))}
      formatOptionLabel={FormatOptionLabel}
      placeholder={placeholder}
      onChange={onChange}
      isSearchable={false}
      options={options}
      value={value}
    />
  </InputWrapper>
);
