import { z } from "zod";
import { PhoneInput, SelectOption } from "../../../../../schemas";

export const ThirdFormSchema = z.object({
    refill: SelectOption,
    phone: PhoneInput,
    bank: SelectOption.optional().or(z.null())
}).superRefine((data, ctx) => {
    if (data.refill.value === "СБП" && !data.bank) {
        ctx.addIssue({
            path: ["bank"],
            message: "Error",
            code: z.ZodIssueCode.custom
        });
    }
});