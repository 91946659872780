import { SelectOption } from "../../../types";

export const STEPS_NUMBER = 3;
export const SECOND_STEP_INDEX = 1;

export const AGREE_LINK = "https://bron.kamkombank.ru/web/uploads/Consent_Processing_Personal_Data.pdf";
export const YES_LINK = "https://review.kamkombank.ru/review/positive";
export const NO_LINK = "https://review.kamkombank.ru/";

export const CITY_FIELD_NAME = 'city';
export const CITY_POINT_FIELD_NAME = 'cityPoint';
export const REFILL_FIELD_NAME = 'refill';
export const BANK_FIELD_NAME = 'bank';

export const cities: SelectOption[] = [
    { label: "Москва", value: "Москва" },
    { label: "Санкт-Петербург", value: "Санкт-Петербург" },
    { label: "Казань", value: "Казань" },
    { label: "Набережные Челны", value: "Набережные Челны" },
    { label: "Альметьевск", value: "Альметьевск" },
    { label: "Ижевск", value: "Ижевск" },
]

export const replenishmentTypes: SelectOption[] = [
    // { label: "На баланс телефона", value: "На баланс телефона" },
    { label: "СБП", value: "СБП" },
]