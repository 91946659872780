import { Link, Text } from "@chakra-ui/react";
import { FormSchema } from "../FormBuilder/FormBuilder.types";
import {
  firstFormElements,
  FirstFormSchema,
  secondFormElements,
  SecondFormSchema,
  thirdFormElements,
  ThirdFormSchema,
} from "./FormSteps";

export const forms: FormSchema[] = [
  {
    header: "Заполните все данные в анкете и отправьте свой отзыв на проверку",
    elements: firstFormElements,
    schema: FirstFormSchema,
    position: "static",
  },
  {
    header: "Добавьте скриншот отзыва",
    description: (
      <Text fontSize="14px" color="text.heading">
        Убедитесь, что на скриншоте четко отображаются данные: никнейм, название филиала, оценка,
        комментарий, дата и статус отзыва.{" "}
        <Link href="https://evoteka.ru/aotziv-examples/" color="accent.red">
          Посмотреть пример отзыва
        </Link>
      </Text>
    ),
    elements: secondFormElements,
    schema: SecondFormSchema,
  },
  {
    header: "Выберите удобный для вас способ получения вознаграждения",
    elements: thirdFormElements,
    schema: ThirdFormSchema as any,
  },
];
