import { Button, Link, Spinner, Text, useBoolean, VStack } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SelectOption } from "../../../../types";
import { ControlledSelect } from "../../../common/CommonSelect/ControlledSelect";
import { ControlledPhoneInput } from "../../../common/PhoneInput/ControlledPhoneInput";
import { Stepper } from "../../../common/Stepper/Stepper";
import { Header } from "../../FormBuilder/FormBuilder.styles";
import { ThirdFormSchema } from "../FormSteps";
import { getBanks } from "../ReviewForm.api";
import { replenishmentTypes } from "../ReviewForm.constants";

export const Third = ({onNext, onBack} : {onNext : any, onBack : any}) => {
  const form = useForm({
    resolver: zodResolver(ThirdFormSchema),
    mode: "onSubmit",
  });

  const {
    getValues,
    watch,
    formState: { isSubmitting },
  } = form;

  const [isValid, setValid] = useState(false);

  const [isBanksVisible, { off: setBanksInvisible, on: setBanksVisible }] = useBoolean();
  const [banks, setBanks] = useState<SelectOption[]>([]);

  const variant = isValid ? "primary" : "secondary";

  useEffect(() => {
    const { value } = form.getValues("refill") || {};

    if (value && value === "СБП") {
      setBanksVisible();
    } else {
      setBanksInvisible();
    }
  }, [form.watch("refill")]);

  useEffect(() => {
    getBanks().then(setBanks);
  }, []);

  // Принудительная валидация формы.
  useEffect(() => {
    try {
      ThirdFormSchema.parse(getValues());
      setValid(true);
    } catch (e) {
      setValid(false);
    }
  }, [watch()]);

  return (
    <FormProvider {...form}>
      <Stepper activeStepIndex={2} steps={3} onBack={onBack} />
      <VStack gap="12px" w="full" alignItems="start">
        <Header noOfLines={3}>Добавьте скриншот отзыва</Header>
        <Text fontSize="14px" color="text.heading">
          Убедитесь, что на скриншоте четко отображаются данные: никнейм, название филиала, оценка,
          комментарий, дата и статус отзыва.{" "}
          <Link href="" color="accent.red">
            Посмотреть пример отзыва
          </Link>
        </Text>
      </VStack>
      <ControlledSelect
        placeholder="Выберите способ пополнения"
        options={replenishmentTypes}
        label="Способ пополнения"
        name="refill"
      />
      <ControlledPhoneInput
        placeholder="+7 (___) ___-__-__"
        label="Введите номер телефона"
        name="phone"
      />
      <ControlledSelect
        placeholder="Выберите банк"
        visible={isBanksVisible}
        label="Выберите банк"
        options={banks}
        name="bank"
      />
      <VStack gap="10px" w="full">
        <Button
          onClick={form.handleSubmit(onNext)}
          opacity={isSubmitting || !isValid ? 0.5 : 1}
          variant={variant}
          gap="10px"
        >
          Далее
          {isSubmitting ? <Spinner size={"sm"} color="white" /> : null}
        </Button>
      </VStack>
    </FormProvider>
  );
};
