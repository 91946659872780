import { DynamicFormElement } from "../../../FormBuilder/FormBuilder.types";

export const thirdFormElements: DynamicFormElement[] = [
  {
    type: "Select",
    name: "refill",
    placeholder: "Выберите способ пополнения",
    label: "Способ пополнения",
    options: [],
  },
  {
    type: "Phone",
    name: "phone",
    placeholder: "+7 (___) ___-__-__",
    label: "Введите номер телефона",
  },
  {
    type: "Select",
    name: "bank",
    placeholder: "Выберите банк",
    label: "Выберите банк",
    options: [],
  },
];
