import { Button, HStack, Image, Link, Text, VStack } from "@chakra-ui/react";

import SuccessEmoji from "../../../../../assets/SuccessEmoji.png";
import { NO_LINK, YES_LINK } from "../../ReviewForm.constants";
import { WhatsAppButton } from "./WhatsAppButton/WhatsAppButton";

export const FormSuccess = () => (
  <VStack gap="50px" pb="40px">
    <VStack w="full" gap="11px">
      <Image src={SuccessEmoji} width="90px" height="90px" />
      <Text fontWeight="600" fontSize="20px" color="text.heading">
        Спасибо за ваш отзыв!
      </Text>
      <Text fontSize="14px" color="text.body" textAlign="center">
        Напоминаем, что по Банки.ру и Сравни мы отправляем по 200₽, а оставшуюся сумму
        <br />
        по 1800₽ зачисляем в течение 3-х рабочих дней после зачтения отзыва на баланс телефона, с
        которого был написан отзыв
      </Text>
    </VStack>
    <VStack w="full" gap="57px">
      <VStack w="full" gap="30px">
        <VStack w="full" gap="16px">
          <Text fontSize="20px" fontWeight="semibold" color="accent.red" textAlign="center">
            Хотите оставить еще отзыв и получить до 5.000₽?
          </Text>
          <HStack w="full" gap="12px">
            <Link href={NO_LINK} textDecoration="none" w="full">
              <Button variant="secondary">Нет</Button>
            </Link>
            <Link href={YES_LINK} textDecoration="none" w="full">
              <Button>Да</Button>
            </Link>
          </HStack>
        </VStack>
        <Text fontSize="14px" textAlign="center">
          Банки.ру и Сравни — по 2000₽
          <br />
          Яндекс, 2ГИС, Google, Банкирос — по 50₽
        </Text>
      </VStack>
      <VStack w="full" gap="10px">
        <Text color="text.body" fontSize="12px">
          Возникли вопросы по отправке отзыва?
        </Text>
        <WhatsAppButton />
      </VStack>
    </VStack>
  </VStack>
);
