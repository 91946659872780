import { LOGO_LINK } from "./Header.constants";
import { Container, Link, LogoWrapper } from "./Header.styles";
import { HeaderProps } from "./Header.types";

export const Header = ({ logo, phone }: HeaderProps) => (
  <Container>
    <LogoWrapper>
      <Link href={LOGO_LINK}>{logo}</Link>
    </LogoWrapper>
    <Link href={`tel:${phone}`}>{phone}</Link>
  </Container>
);
