import { ReactNode } from "react";
import { FirstFormStep, SecondFormStep, ThirdFormStep } from "../../ReviewForm/FormSteps";
import { FormSwitcherProps } from "./FormSwitcher.types";

const Steps: Record<number, (props: any) => ReactNode> = {
  0: (props) => <FirstFormStep {...props} />,
  1: (props) => <SecondFormStep {...props} />,
  2: (props) => <ThirdFormStep {...props} />,
};

export const FormSwitcher = ({ activeIndex, ...props }: FormSwitcherProps): React.JSX.Element => {
  return Steps[activeIndex](props) as React.JSX.Element;
};
