import { Button, Link, Spinner, useBoolean, VStack } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SelectOption } from "../../../types";
import { Stepper } from "../../common";
import { ControlledCheckbox } from "../../common/Checkbox/ControlledCheckbox";
import { ControlledInput } from "../../common/CommonInput/ControlledInput";
import { ControlledSelect } from "../../common/CommonSelect/ControlledSelect";
import { Header } from "../FormBuilder/FormBuilder.styles";
import { FirstFormSchema } from "../ReviewForm/FormSteps";
import { getAddress, getPlatforms } from "../ReviewForm/ReviewForm.api";
import { AGREE_LINK, cities } from "../ReviewForm/ReviewForm.constants";

const CITY_KEY = "city";
const CITY_POINT_KEY = "cityPoint";

export const First = ({ onNext, defaultValues }: { onNext: any; defaultValues: any }) => {
  const form = useForm({
    resolver: zodResolver(FirstFormSchema),
    mode: "onSubmit",
  });

  const {
    getValues,
    watch,
    formState: { isSubmitting },
  } = form;

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const [isValid, setValid] = useState(false);

  const variant = isValid ? "primary" : "secondary";

  const [isAddressVisible, { off: setAddressInvisible, on: setAddressVisible }] = useBoolean();

  const [isFirstRender, setFirstRender] = useState(true);
  const [platforms, setPlatforms] = useState<SelectOption<{ icon: string; name: string }>[]>([]);
  const [address, setAddress] = useState<SelectOption[]>([]);

  // Принудительная валидация формы.
  useEffect(() => {
    try {
      FirstFormSchema.parse(getValues());
      setValid(true);
    } catch (e) {
      setValid(false);
    }
  }, [watch()]);

  // При обновлении опции города стираем выбранное значение адреса.
  useEffect(() => {
    const { value } = form.getValues(CITY_KEY) || {};

    if (value) {
      // getAddress(value).then(setAddress);
      setAddressVisible();
    } else {
      setAddressInvisible();
    }

    if (!isFirstRender) {
      form.setValue(CITY_POINT_KEY, null);
    } else {
      setFirstRender(false);
    }
  }, [form.watch(CITY_KEY)]);

  useEffect(() => {
    getPlatforms().then(setPlatforms);
  }, []);

  return (
    <FormProvider {...form}>
      <Stepper activeStepIndex={0} steps={3} onBack={() => {}} />
      <Header noOfLines={3}>
        Заполните все данные в анкете и отправьте свой отзыв на проверку
      </Header>
      <ControlledInput name="name" placeholder="Имя" label="Введите имя" isRequired />
      <ControlledSelect
        label="Укажите город"
        placeholder="Город"
        options={cities}
        name="city"
        isRequired
      />
      <ControlledSelect
        placeholder="Выберите филиал"
        visible={isAddressVisible}
        label="Выберите филиал"
        options={address}
        name="cityPoint"
        isRequired
      />
      <ControlledSelect
        placeholder="Выберите площадку"
        label="Выберите площадку"
        options={platforms}
        isRequired
        name="app"
      />
      <ControlledCheckbox name="agree">
        <>
          Я согласен на{" "}
          <Link href={AGREE_LINK} textDecoration="none" color="accent.red">
            обработку персональных данных
          </Link>
        </>
      </ControlledCheckbox>
      <VStack gap="10px" w="full">
        <Button
          onClick={form.handleSubmit(onNext)}
          opacity={isSubmitting || !isValid ? 0.5 : 1}
          variant={variant}
          gap="10px"
        >
          Далее
          {isSubmitting ? <Spinner size={"sm"} color="white" /> : null}
        </Button>
      </VStack>
    </FormProvider>
  );
};
