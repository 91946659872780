export { FirstFormSchema } from "./FirstFormStep/FirstFormStep.schema";
export { SecondFormSchema } from "./SecondFormStep/SecondFormStep.schema";
export { ThirdFormSchema } from "./ThirdFormStep/ThirdFormStep.schema";

export { firstFormElements } from "./FirstFormStep/FirstFormStep.data";
export { secondFormElements } from "./SecondFormStep/SecondFormStep.data";
export { thirdFormElements } from "./ThirdFormStep/ThirdFormStep.data";

export { FirstFormStep } from "./FirstFormStep/FirstFormStep";
export { SecondFormStep } from "./SecondFormStep/SecondFormStep";
export { ThirdFormStep } from "./ThirdFormStep/ThirdFormStep";

