import { Image, Text } from "@chakra-ui/react";
import { Container } from "./WhatsAppButton.styles";

export const WhatsAppButton = () => (
  <Container href="https://wa.me/+79869209538">
    <Image src="./WhatsApp.png" w="24px" h="24px" alt="WhatsApp" />
    <Text color="#135F20" fontSize="14px">
      Связаться с нами
    </Text>
  </Container>
);
