import { Link } from "@chakra-ui/react";
import { DynamicFormElement } from "../../../FormBuilder/FormBuilder.types";
import { AGREE_LINK } from "../../ReviewForm.constants";

export const firstFormElements: DynamicFormElement[] = [
  {
    type: "Input",
    name: "name",
    placeholder: "Имя",
    label: "Введите имя",
    isRequired: true,
  },
  {
    type: "Select",
    name: "city",
    placeholder: "Город",
    label: "Укажите город",
    isRequired: true,
    options: [],
  },
  {
    type: "Select",
    name: "cityPoint",
    placeholder: "Выберите филиал",
    label: "Выберите филиал",
    isRequired: true,
    options: [],
  },
  {
    type: "Select",
    name: "app",
    placeholder: "Выберите площадку",
    label: "Выберите площадку",
    isRequired: true,
    options: [],
  },
  {
    type: "Checkbox",
    name: "agree",
    children: (
      <>
        Я согласен на{" "}
        <Link href={AGREE_LINK} textDecoration="none" color="accent.red">
          обработку персональных данных
        </Link>
      </>
    ),
  },
];
